// react
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";

// styles
import "./landingpagestyle.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const LandingPageComponent = (props) => {
  let history = useHistory();

  const [age, setAge] = React.useState("");
  const [selectIdOption, setSelectIdOption] = React.useState([]);

  const classes = useStyles();

  useEffect(() => {
    fetch(
      "https://media.rgi-report.satsure.co/reliance/regions/regions.json"
    )
      .then((response) => response.json())
      .then((data) => {
        var dataArr = data.regions;
        var arr = [];
        for (let i in dataArr) {
          var obj = {
            text: `${dataArr[i].state} - ${dataArr[i].district}`,
            value: dataArr[i].regionId,
          };
          arr.push(obj);
        }
        setSelectIdOption(arr);
      });
  }, []);

  const logout = () => {
    localStorage.clear();
    history.replace('/', { replace: true });
  };
  const handleChange = (event) => {
    history.replace(`/pdf-layout/${event.target.value}`);
  };
  return (
    <div className="header-sections">
      <div>
        <h2>Select District</h2>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={age}
            onChange={handleChange}
            label="Id"
          >
            {selectIdOption.map((data, key) => {
              return <MenuItem value={data.value}> {data.text} </MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>

      <div className="logout">
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
};

export default LandingPageComponent;

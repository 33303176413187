import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Route
} from "react-router-dom";

// componets
import Login from '../cointainer/login';
import LandingPageLayout from "../component/landing-page-component/LandingPageComponent";
import PdfLayout from "../component/pdf-component/PdfComponent";

const Routes = () => {
  return (
    <Router history={useHistory}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/landing" component={LandingPageLayout} />
        <Route exact path="/pdf-layout/:id" component={PdfLayout} />
      </Switch>
    </Router>
  );
};

export default Routes;


import React from "react";

// styles
import "./pageloader.css";

const PageLoader = () => {
    return (
        <div className="pageLoader-sections">
            <div className="loader">
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default PageLoader;

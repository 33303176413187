// react
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";

// Pdf
import { useReactToPrint } from "react-to-print";
// styles
import "./pdfstyle.css";

// component
import PageLoader from "../pageLoader/PageLoader";
import ImageUploading from "react-images-uploading";

//Image
import pdfLogo from "../../assets/images/pdf.png";
import docLogo from "../../assets/images/doc.png";
import relianceLogo from "../../assets/images/reliance-logo.png";
import satsureLogo from "../../assets/images/Satsure-Sparta.svg";
import cropLogo from "../../assets/images/sowing-methodology.jpeg";
import cropAcreageLogo from "../../assets/images/crop-acreage.jpeg";
import deleteIcon from "../../assets/images/delete.png";
import upload from "../../assets/images/upload.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const initialState = { alt: "", src: "" };

const PdfComponent = () => {
  let history = useHistory();
  const classes = useStyles();

  const [pdfComponentLoader, setPdfComponentLoader] = React.useState(false);
  const [pageLoader, setPageLoader] = React.useState(false);
  const [baseUrl, setBaseUrl] = React.useState("");
  const [baseRegion, setBaseRegion] = React.useState("");
  const [state, setState] = React.useState([]);
  const [sourceLink, setSourceLink] = React.useState([]);
  const [district, setDistrict] = React.useState([]);
  const [tittleBackGroundImage, setTittleBackGroundImage] = React.useState([]);
  const [cropsIdentifiedImage, setCropsIdentifiedImage] = React.useState([]);
  const [studyAreaText, setStudyAreaText] = React.useState([]);
  const [studyAreaImage, setStudyAreaImage] = React.useState([]);
  const [rainfallDeviationImage, setRainfallDeviationImage] = React.useState(
    []
  );
  const [cropHealthMonitoring, setcropHealthMonitoring] = React.useState([]);
  const [pieChat, setPieChat] = React.useState([]);
  const [ndviMap, setNdviMap] = React.useState([]);
  const [ndviMapLeg, setNdviMapLeg] = React.useState([]);
  const [rDLeg, setRDLeg] = React.useState([]);
  const [ndviChartImage, setNdviChartImage] = React.useState([]);
  const [ndwiMap, setNdwiMap] = React.useState([]);
  const [ndwiMapLeg, setNdwiMapLeg] = React.useState([]);
  const [ndwiChartImage, setNdwiChartImage] = React.useState([]);
  const [cropSowingTitle1Image, setcropSowingTitle1Image] = React.useState([]);
  const [cropSowing1Image, setcropSowing1Image] = React.useState([]);
  const [cropSowingTitle2Image, setcropSowingTitle2Image] = React.useState([]);
  const [cropSowing2Image, setcropSowing2Image] = React.useState([]);
  const [cropAcreageImage, setcropAcreageImage] = React.useState([]);
  const [cropAcreageImage2, setcropAcreageImage2] = React.useState([]);
  const [cropAcreageImage3, setcropAcreageImage3] = React.useState([]);
  const [cropPerformanceImage, setCropPerformanceImage] = React.useState([]);
  const [cropHarvest1Image, setCropHarvest1Image] = React.useState([]);
  const [cropHarvest2Image, setCropHarvest2Image] = React.useState([]);
  const [cropHarvest3Image, setCropHarvest3Image] = React.useState([]);
  const [fieldPhotographsImage, setfieldPhotographsImage] = React.useState([]);
  const [cropCalendarImage, setCropCalendarImage] = React.useState([]);
  const [cropSownPercentageImage, setCropSownPercentageImage] = React.useState(
    []
  );
  const [sapPlotImage, setSapPlotImage] = React.useState([]);
  const [repeatHeaders, setRepeatHeaders] = React.useState(true);

  const [{ alt, src }, setPreview] = React.useState(initialState);
  const [images, setImages] = React.useState([]);
  const [images1, setImages1] = React.useState([]);
  const maxNumber = 4;
  const maxNumber1 = 4;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const onChange1 = (imageList1, addUpdateIndex) => {
    // data for submit
    setImages1(imageList1);
  };
  //
  useEffect(() => {
    setPageLoader(true);
    var url = window.location.href;
    var splitData = url.split("/");
    var regionId = splitData.pop();
    setBaseRegion(regionId);

    const urlData =
      "https://media.rgi-report.satsure.co/reliance/regions/r024GS/" +
      regionId +
      "/";
    setBaseUrl(urlData);

    const rDLegend =
      "https://media.rgi-report.satsure.co/reliance/regions/RainfallDeviation.png";
    setRDLeg(rDLegend);

    const ndviLegend =
      "https://media.rgi-report.satsure.co/reliance/regions/Ndvi_Legend.png";
    setNdviMapLeg(ndviLegend);

    const ndwiLegend =
      "https://media.rgi-report.satsure.co/reliance/regions/Ndwi_Legend.png";
    setNdwiMapLeg(ndwiLegend);
    fetch(
      "https://media.rgi-report.satsure.co/reliance/regions/r024GS/" +
      regionId + "/data.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setTimeout(() => {
          setPdfComponentLoader(true);
        }, 500);
        setTimeout(() => {
          setPageLoader(false);
        }, 10000);
        const regionData = data;

        const state = regionData.regionState;
        setState(state);
        //
        const district = regionData.regionDistrict;
        setDistrict(district);
        //
        const sourceLink = regionData.sourceLink;
        setSourceLink(sourceLink);
        //
        const tittleBackGround = regionData.titleBackground;
        setTittleBackGroundImage(tittleBackGround);
        //
        const cropsIdentified = regionData.cropsIdentified;
        setCropsIdentifiedImage(cropsIdentified);
        //
        const studyAreaText = regionData.studyAreaText;
        setStudyAreaText(studyAreaText);
        //
        const studyArea = regionData.studyArea;
        setStudyAreaImage(studyArea);
        //
        const rainfallDeviation = regionData.rainfallDeviation;

        var rainfallDeviationArr = [];
        for (let i in rainfallDeviation) {
          var rainfallDeviationObj = rainfallDeviation[i];
          for (let j in rainfallDeviationObj) {
            var objectrainfallDeviation = {
              img: j,
              label: rainfallDeviationObj[j],
            };
            rainfallDeviationArr.push(objectrainfallDeviation);
          }
        }
        setRainfallDeviationImage(rainfallDeviationArr);
        //
        const cropHealthMonitoring = regionData.cropHealthMonitoring;
        setcropHealthMonitoring(cropHealthMonitoring);
        //setPieChat
        const pieChart = regionData.pieChart;
        setPieChat(pieChart);
        //
        const ndviMap = regionData.ndviMap;
        var ndviArr = [];
        for (let i in ndviMap) {
          var ndviMapObj = ndviMap[i];
          for (let j in ndviMapObj) {
            var object = { img: j, label: ndviMapObj[j] };
            ndviArr.push(object);
          }
        }
        setNdviMap(ndviArr);
        //
        const ndviChart = regionData.ndviChart;
        setNdviChartImage(ndviChart);
        // ndwiMap
        const ndwiMap = regionData.ndwiMap;
        var ndwiArr = [];
        for (let i in ndwiMap) {
          var ndwiMapObj = ndwiMap[i];
          for (let j in ndwiMapObj) {
            var object1 = { img: j, label: ndwiMapObj[j] };
            ndwiArr.push(object1);
          }
        }
        setNdwiMap(ndwiArr);
        //
        const ndwiChart = regionData.ndwiChart;
        setNdwiChartImage(ndwiChart);
        //
        const cropSowingTitle1 = regionData.cropSowingTitle1;
        setcropSowingTitle1Image(cropSowingTitle1);
        //
        const cropSowing1 = regionData.cropSowing1;
        setcropSowing1Image(cropSowing1);
        //
        const cropSowingTitle2 = regionData.cropSowingTitle2;
        setcropSowingTitle2Image(cropSowingTitle2);
        //
        const cropSowing2 = regionData.cropSowing2;
        setcropSowing2Image(cropSowing2);
        //
        const cropAcreage = regionData.cropAcreage;
        setcropAcreageImage(cropAcreage);
        //
        const cropAcreage2 = regionData.cropAcreage2;
        setcropAcreageImage2(cropAcreage2);
        //
        const cropAcreage3 = regionData.cropAcreage3;
        setcropAcreageImage3(cropAcreage3);
        //
        const cropPerformance = regionData.cropPerformance;
        setCropPerformanceImage(cropPerformance);
        //
        const cropHarvest1 = regionData.cropHarvest1;
        setCropHarvest1Image(cropHarvest1);
        //
        const cropHarvest2 = regionData.cropHarvest2;
        setCropHarvest2Image(cropHarvest2);
        //
        const cropHarvest3 = regionData.cropHarvest3;
        setCropHarvest3Image(cropHarvest3);
        //
        const fieldPhotographs = regionData.fieldPhotographs;
        setfieldPhotographsImage(fieldPhotographs);
        //
        const cropCalendar = regionData.cropCalendar;
        setCropCalendarImage(cropCalendar);
        // cropsownpercentage
        const cropsownpercentage = regionData.cropsownpercentage;
        setCropSownPercentageImage(cropsownpercentage);
        // setSapPlotImage
        const sapPlot = regionData.sapPlot;
        setSapPlotImage(sapPlot);
      });
  }, []);
  //Back
  const btnCallBack = () => {
    history.replace(`/landing`);
  };
  //
  const container = React.useRef();
  const exportPDFWithComponent = useReactToPrint({
    content: () => container.current,
  });

  const exportDoc = () => {
    var filename = "rgi";
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html =
      preHtml + document.getElementById("exportContent").innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };
  const fileHandler = (event) => {
    const { files } = event.target;
    setPreview(
      files.length
        ? {
          src: URL.createObjectURL(files[0]),
          alt: files[0].name,
        }
        : initialState
    );
  };
  if (pdfComponentLoader) {
    return (
      <div className="pdf-sections">
        <div className="back-btn">
          <Button
            onClick={btnCallBack}
            variant="contained"
            // color="secondary"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </div>
        {/* Export Pdf */}
        {pageLoader ? null : (
          <div className="export">
            <div
              className="export-pdf"
              onClick={exportPDFWithComponent}
              id="repeatHeaders"
              onChange={() => setRepeatHeaders(!repeatHeaders)}
            >
              <label htmlFor="repeatHeaders">
                <img src={pdfLogo} alt="Satsure Logo" />
              </label>
            </div>
            <div className="export-doc" onClick={exportDoc}>
              {/* onClick={exportDoc("exportContent")}> */}

              <label>
                <img alt="doclogo" src={docLogo} />
              </label>
            </div>
          </div>
        )}
        <div id="exportContent" ref={container}>
          <div className="report-wrapper">
            {/*  Header Logo  */}
            <div className="header-logo">
              <div className="logo-icon">
                <img src={relianceLogo} alt="Satsure Logo" />
              </div>
            </div>
            {/* Title */}
            <div className="tittlebottom">
              <div className="margin-bottom-30 report-container">
                <div className="report-sections">
                  <div className="report-bg">
                    {tittleBackGroundImage.map((data, key) => {
                      var urlImg = baseUrl + data;
                      return (
                        <div className="report-bg-img">
                          <img src={urlImg} alt="crop Logo" />
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="report-title">
                    <label className="heading-text">
                      District Level Report on Crop Health Monitoring
                    </label>
                    <div className="country-details">
                      <label>{state}</label> - <span>{district}</span>
                      <p>Kharif - 2023</p>
                    </div>
                  </div> */}
                  {/* <div className="country-details">
                                    <span>{district}</span>
                                </div> */}
                  {/* <div className="published">
                                    <textarea
                                 placeholder="Max 300 characters"

                                    ></textarea>
                                </div> */}
                </div>
              </div>
              {/* <label className="number"></label> */}
            </div>
            <div className="report-content-sections">
              {/* Table Content */}
              <div className="nobottom">
                <div className="table-contents page-break">
                  <label className="table-content-title">Table of Contents</label>
                  <ul className="margin-bottom-30 table-content-list">
                    <li>
                      <Link to="introduction" spy={true} smooth={true}>
                        <label className="title">1. Introduction</label>
                        <label className="chapter">1</label>
                      </Link>
                      {/* <p className="common-heading">1</p> */}
                    </li>
                    <li>
                      <Link to="scope" spy={true} smooth={true}>
                        <label>2. Objective and Scope of Work</label>
                        <label className="chapter">1</label>
                      </Link>
                    </li>
                    <li>
                      <Link to="material" spy={true} smooth={true}>
                        <label>3. Material and Methodology</label>
                        <label className="chapter">1</label>
                      </Link>
                      <ul className="table-content-heading">
                        <li>
                          <Link to="dataMaterial" spy={true} smooth={true}>
                            <label>3.1 Data products used</label>
                            <label className="chapter">1</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="sentinel" spy={true} smooth={true}>
                            <label>3.2 Satellite Derived Indices</label>
                            <label className="chapter">2</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="cropsowing" spy={true} smooth={true}>
                            <label>3.3 Crop Sowing</label>
                            <label className="chapter">3</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="cropacreage" spy={true} smooth={true}>
                            <label>3.4 Crop Acreage</label>
                            <label className="chapter">4</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="cropperformance" spy={true} smooth={true}>
                            <label>3.5 Crop Performance</label>
                            <label className="chapter">5</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="harvestprogression" spy={true} smooth={true}>
                            <label>3.6 Harvest Progression</label>
                            <label className="chapter">5</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="gtvalidation" spy={true} smooth={true}>
                            <label>3.7 GT Validation </label>
                            <label className="chapter">5</label>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="studyarea" spy={true} smooth={true}>
                        <label>4. Study Area</label>
                        <label className="chapter">6</label>
                      </Link>
                      <ul className="table-content-heading">
                        <li>
                          <Link to="areaPre" spy={true} smooth={true}>
                            <label>4.1 Crop Sown Area Percentage</label>
                            <label className="chapter">6</label>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="weatheroutlook" spy={true} smooth={true}>
                        <label> 5. Weather Outlook</label>
                        <label className="chapter">8</label>
                      </Link>
                    </li>
                    <li>
                      <Link to="healthmonitoring" spy={true} smooth={true}>
                        <label> 6. Crop Health Monitoring</label>
                        <label className="chapter">9</label>
                      </Link>
                      <ul className="table-content-heading">
                        <li>
                          <Link to="normalized" spy={true} smooth={true}>
                            <label>
                              6.1 Normalized Difference Vegetation Index
                            </label>
                            <label className="chapter">12</label>
                          </Link>
                        </li>
                        <li>
                          <Link to="waterindex" spy={true} smooth={true}>
                            <label>6.2 Normalized Difference Water Index</label>
                            <label className="chapter">14</label>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="discussion" spy={true} smooth={true}>
                        <label>7. Results and Discussion</label>
                        <label className="chapter">16</label>
                      </Link>
                      <ul className="table-content-heading">
                        <li>
                          <Link to="district" spy={true} smooth={true}>
                            <label>
                              7.1 <span className="dpadding">{district} </span>
                              District
                            </label>
                            <label className="chapter">16</label>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="photographs" spy={true} smooth={true}>
                        <label> 8. Field Photographs</label>
                        <label className="chapter">20</label>
                      </Link>
                    </li>
                    <li>
                      <Link to="conclusion" spy={true} smooth={true}>
                        <label>9. Conclusion</label>
                        <label className="chapter">22</label>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <label className="number"></label> */}
              </div>
              {/* Introduction */}
              <div className="nobottom">
                <div className="page-break">
                  <h3 className="common-heading" id="introduction">
                    1. Introduction
                  </h3>
                  <p className="common-para">
                    Pradhan Mantri Fasal Bima Yojana (PMFBY) is a national
                    flagship program for providing crop insurance in the country
                    and aims to leverage technology in various studies for acreage
                    estimation, crop stress, harvest progression, smart sampling
                    for crop cutting and crop yield estimation.
                  </p>
                  <p className="common-para">
                    Today, with the civilian use of Remote Sensing technology, it
                    is possible to obtain real time comprehensive and quantitative
                    information with high accuracy and wide scope to continuously
                    obtain ground information on agricultural land. Currently, two
                    national level agencies viz. Mahalanobis National Crop
                    Forecast Centre (MNCFC) and National Remote Sensing Centre
                    (NRSC) and a few private agencies are carrying out Remote
                    Sensing (RS) based studies towards improving the estimates on
                    crop acreage, yield and smart sampling for Crop Cutting
                    Experiments which are key input to the development of
                    methodologies for crop insurance.
                  </p>
                  <p className="common-para">
                    With the release of the revamped PMFBY guidelines, government
                    of India has emphasized on Insurance Companies and State
                    Governments to conduct remote sensing studies in various
                    aspects of crop risks. Reliance General Insurance Company
                    Limited (RGICL) has partnered with SatSure to correlate the
                    progression of sowing, crop growth and crop health status by
                    using satellite-based data and insights from SatSure along
                    with extensive ground truthing observations from within RGICL.
                  </p>
                </div>
                {/*  Objective and Scope of Work */}
                <div className="scope">
                  <h3 className="common-heading" id="scope">
                    2. Objective and Scope of Work
                  </h3>
                  <p className="common-para">
                    The objective of this study is to perform crop classification
                    using the on-ground data available. The study also monitors
                    the crop condition from sowing till harvest on a real time
                    basis. The information like risks involved during maturity
                    stage, crop damage due to local calamities causing crop losses
                    are delivered, with the help of geospatial and weather data.
                    {/* The analysis is carried out from 1st Fortnight of November to 2nd
                                    Fortnight of December 2021. */}
                  </p>
                </div>

                {/* <!-- Material and Methodology --> */}

                {/* <div className="methodology"> */}
                <h3 id="material" className="common-heading">
                  3. Material and Methodology
                </h3>
                <h4 id="dataMaterial" className="common-sub-heading">
                  3.1 Data products used
                </h4>
                <h5 id="sentinel" className="common-min-heading">
                  3.1.1 Sentinel Imagery
                </h5>
                <p className="common-para">
                  Sentinel-2 is an Earth observation mission from the Copernicus
                  Programme that systematically acquires optical imagery at high
                  spatial resolution (10 m to 60 m) over land and coastal waters.
                  The Sentinel-2 satellites each carry a single multi-spectral
                  instrument (MSI) with 13 spectral channels in the visible/near
                  infrared (VNIR) and short wave infrared spectral range (SWIR).
                  The satellite revisits every 5 - 6 days under the same viewing
                  angles. At high latitudes, Sentinel-2 swath overlap and some
                  regions will be observed twice or more every 5 - 6 days, but
                  with different viewing angles. The spatial resolution of Red,
                  Green, Blue and Near Infrared bands of Sentinel -2 data is 10
                  meters. The mission provides information for agricultural and
                  forestry practices and for helping manage food security.
                  Satellite images will be used to determine various plant indices
                  such as leaf area chlorophyll and water content indexes. This is
                  particularly important for effective yield prediction and
                  applications related to Earth's vegetation.
                </p>
                <p className="common-para">
                  Sentinel-1 is the first of the Copernicus Programme satellite
                  constellation conducted by the European Space Agency. This
                  mission is composed of a constellation of two satellites,
                  Sentinel-1A and Sentinel-1B, which share the same orbital plane.
                  They carry a C-band synthetic-aperture radar instrument which
                  provides a collection of data in all-weather, day or night. This
                  instrument has a spatial resolution of down to 5m - 20m and a
                  swath of up to 400 km. The Sentinel Data is used to derive the
                  flood inundation area and analyze the crop damage area.
                </p>
                <label className="number"></label>
              </div>
              <div className="nobottom">
                <h4 className="common-sub-heading page-break">
                  3.2 Satellite Derived Indices
                </h4>
                <p className="common-para">
                  Spectral indices are combinations of spectral reflectance from
                  two or more wavelengths that indicate the relative abundance of
                  features of interest. Vegetation indices are the most popular
                  type.
                </p>
                <h5 className="common-min-heading">
                  3.2.1 Normalized Difference Vegetation Index (NDVI)
                </h5>
                <p className="common-para">
                  NDVI is calculated from the Visible (Red) and Near-Infrared
                  light reflected by vegetation. Healthy vegetation absorbs most
                  of the Visible Light that hits it, and reflects a large portion
                  of the Near-Infrared light. Unhealthy or sparse vegetation
                  reflects more visible light and less Near-Infrared light. Nearly
                  all satellite Vegetation Indices employ this difference formula
                  to quantify the density of plant growth on the Earth
                  Near-Infrared Radiation (NIR) minus a part of Visible radiation
                  (RED) divided by Near-Infrared radiation (NIR) plus a part of
                  visible radiation (RED). The result of this formula is called
                  the Normalized Difference Vegetation Index (NDVI). The NDVI is
                  mathematically written as,
                </p>
                <label className="formula">
                  NDVI = (NIR - RED) / (NIR + RED)
                </label>
                <p className="common-para">
                  Calculations of NDVI for a given pixel always result in a number
                  that ranges from minus one (-1) to plus one (+1). No green
                  leaves give a value close to zero. A zero means no vegetation,
                  and close to +1 (0.8 - 0.9) indicates the highest possible
                  density of green leaves. In this study the NDVI values are
                  scaled between 0 and 200 using the following equation:
                </p>
                <label className="formula">
                  Scaled NDVI = (NDVI x 100) + 100
                </label>
                <label className="formula">
                  Where the NDVI values range between -1 to +1
                </label>
                <div className="crop-showing-img ndi-img">
                  <img src={ndviMapLeg} alt="ndvi leg" />
                </div>
                <label className="figures top-0">
                  Figure 1 : Legend for scaled NDVI
                </label>
                <h5 className="common-min-heading">
                  3.2.2 Normalized Difference Water Index (NDWI)
                </h5>
                <p className="common-para">
                  Normalized Difference Water Index (NDWI) is used to refer to
                  either one of two remote sensing derived indexes related to
                  liquid water. In this analysis, the NDWI is used in the context
                  of monitoring water content of leaves, using NIR and Short-wave
                  Infrared (SWIR) wavelengths. The SWIR reflectance is a proxy for
                  changes in both vegetation water content and the spongy
                  mesophyll structure in vegetation canopies. The NIR reflectance
                  is however, affected by leaf internal structure and leaf dry
                  matter content. The combination of the NIR with the SWIR removes
                  variations induced by leaf internal structure and leaf dry
                  matter content. Mathematically NDWI is represented as
                </p>
                <label className="formula">
                  NDWI = (NIR - SWIR) / (NIR + SWIR)
                </label>
                <p className="common-para">
                  Calculations of NDWI for a given pixel always result in a number
                  that ranges from minus one (-1) to plus one (+1). Values from -1
                  to 0 indicates a bright surface with no vegetation or water
                  content, while closer to +1 indicates presence of water. In this
                  study the NDWI values are scaled between 0 and 200 using the
                  following equation:
                </p>
                <label className="formula">
                  Scaled NDWI = (NDWI x 100) + 100
                </label>
                <label className="formula">
                  Where the NDWI values range between -1 to +1
                </label>
                <div className="crop-showing-img ndi-img">
                  <img src={ndwiMapLeg} alt="Ndvi Map Logo" />
                </div>
                <label className="figures top-0">
                  Figure 2 : Legend for scaled NDWI
                </label>
                <label className="number"></label>
              </div>
              <div className="nobottom">
                <h4 id="cropsowing" className="common-sub-heading page-break">
                  3.3 Crop Sowing
                </h4>
                <div className="crop-showing">
                  <p className="common-para">
                    Crop Sowing means the process of planting and the area of
                    sowing is termed as crop sown area. Crop sown area gives us
                    the initial forecast of the crop productivity in a desired
                    area. The Remote Sensing methodology for estimating the crop
                    sowing is shown in Fig.3
                  </p>
                  <div className="crop-showing-img">
                    <img src={cropLogo} alt="Crop Logo" />
                  </div>
                  <label className="figures">
                    Figure 3 : Crop Sowing Methodology
                  </label>
                </div>
                <label className="number"></label>
              </div>
              <div className="nobottom">
                <h4 id="cropacreage" className="common-sub-heading page-break">
                  3.4 Crop Acreage
                </h4>
                <p className="common-para">
                  Crop Acreage indicates the area of a specific crop planted in a
                  cropping season. Different crops are identified based on NDVI
                  time series, which are unique to each crop. The Image
                  classification is carried out, and Crop Acreage is derived using
                  the following equation:
                </p>
                <label className="formula">
                  Acreage=No. of Pixels × Pixel Area/10000
                </label>
                <p className="common-para">
                  Where – Number of Pixels derived from the image classification
                  for a crop; Pixel Area is the area covered by the pixel at the
                  lowest resolution; Acreage is in Hectares (Ha)
                </p>
                <p className="common-para">
                  The Remote Sensing methodology for estimation of Crop Acreage is
                  shown in Fig.4
                </p>
                <div className="crop-showing">
                  <div className="crop-showing-img">
                    <img src={cropAcreageLogo} alt="Crop Acerage" />
                  </div>
                  <label className="figures">
                    Figure 4 : Crop Acreage Methodology
                  </label>
                </div>
                <label className="number"></label>
              </div>
              <div className="nobottom">
                <h4
                  id="cropperformance"
                  className="page-break common-sub-heading"
                >
                  3.5 Crop Performance
                </h4>
                <p className="common-para">
                  Relative yield is a measure to assess the performance of the
                  crop for the season relative to each district. The crop
                  performance is divided into following categories: Below average,
                  Average, Good and Very good. It is the signal indicator to check
                  in which area of the district, the crop has performed better in
                  comparison with compared with other parts of the district.
                  Relative yield of the crop is calculated using the vegetative
                  index captured phenology of the crop.
                </p>
                <h4 id="harvestprogression" className="common-sub-heading">
                  3.6 Harvest Progression
                </h4>
                <p className="common-para">
                  The area of crop harvested over a period is called Harvest
                  Progression. The harvest information is derived when the area is
                  in progress of harvesting.
                </p>
                <h4 id="gtvalidation" className="common-sub-heading">
                  3.7 GT Validation
                </h4>
                <p className="common-para">
                  The crop classified region using the satellite data is validated
                  with the Ground Truth points provided. The ground truth points
                  help in capturing the actual crop areas and improve the
                  classification accuracy. The validation percentage is calculated
                  as follows:
                </p>
                <label className="formula">
                  Validation Percentage (%)= Number of Points overlaying / Number
                  of Points Available x 100
                </label>
                <label className="number"></label>
              </div>
              {/* <!-- Study Area --> */}
              <div>
                <div className="nobottom">
                  <h3 id="studyarea" className=" page-break common-heading">
                    4. Study Area
                  </h3>
                  <p className="common-para">
                    {studyAreaText} as shown in Fig:-5
                  </p>
                  {/* <div className="text-area">
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        maxLength="300"
                                        placeholder="Max 300 characters"
                                    />
                                </div> */}
                  {studyAreaImage.length !== 0 ? (
                    <div className="crop-showing">
                      {studyAreaImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">Figure 5 : Study Area</label>
                    </div>
                  ) : null}
                  {cropsIdentifiedImage.length !== 0 ? (
                    <div className="crop-identify">
                      {cropsIdentifiedImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Table 1 : Geographical Information
                      </label>
                    </div>
                  ) : null}
                  <label className="number"></label>
                </div>
                {cropSownPercentageImage.length !== 0 ? (
                  <div className="nobottom">
                    <h4 id="areaPre" className="common-sub-heading page-break">
                      4.1 Crop Sown Area Percentage
                    </h4>
                    <div className="crop-showing dis-wise">
                      {cropSownPercentageImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="Crop Sown" />
                          </div>
                        );
                      })}
                    </div>
                    <label className="figures">
                      Figure 6 : District wise Crop Sown Percentage <br />
                      <a
                        href="http://agriharyana.gov.in"
                        rel="noreferrer"
                        target="_blank"
                      >
                        (Source: <label>{sourceLink}</label>)
                      </a>
                    </label>
                    <label className="number"></label>
                  </div>
                ) : null}

                {sapPlotImage.length !== 0 ? (
                  <div className="page-break nobottom">
                    <div className="crop-showing">
                      {sapPlotImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="Crop Sown" />
                          </div>
                        );
                      })}
                    </div>
                    <label className="figures">
                      Figure 6 : District wise Crop Sown Percentage Bar Chart
                      <br />
                      <a href={sourceLink} rel="noreferrer" target="_blank">
                        (Source: {sourceLink})
                      </a>
                    </label>
                    <label className="number"></label>
                  </div>
                ) : null}
              </div>

              {/* <!-- Weather Outlook --> */}
              <div className="page-break nobottom">
                <h3 id="weatheroutlook" className="common-heading">
                  5. Weather Outlook
                </h3>
                <div className="text-area">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    maxLength="750"
                    placeholder="Max 750 characters"
                  />
                </div>
                <ul className="ndvi-images">
                  {rainfallDeviationImage.map((item) => {
                    var urlImg = baseUrl + item.img;
                    return (
                      <li>
                        <div>
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="Satsure Logo" />
                          </div>
                          <label className="figures">{item.label}</label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="crop-showing-img ndi-img">
                  <img src={rDLeg} alt="ndvi leg" />
                </div>
                <label className="figures">
                  Figure 7 : Fortnightly Rainfall Deviation <br /> (Source: Global
                  Precipitation Measurement (GPM) & Indian Meteorological
                  Department (IMD))
                </label>
                <label className="number"></label>
              </div>
              {/* <!-- Crop Health Monitoring (CHM) --> */}
              <div>
                <h3 id="healthmonitoring" className="common-heading page-break">
                  6. Crop Health Monitoring (CHM)
                </h3>
                <p className="common-para">
                  Field Condition survey and crop health monitoring with various
                  parameters was carried out and the points are shown health wise
                  and crop wise in figures below.
                </p>
                {cropHealthMonitoring.length !== 0 ? (
                  <div className="crop-showing">
                    <ul>
                      <li className="chm-img">
                        {cropHealthMonitoring.map((item) => {
                          var urlImg = baseUrl + item.img;
                          return (
                            <div className="page-break-after nobottom">
                              <div className="crop-showing-img">
                                <img src={urlImg} alt="crop Health Logo" />
                              </div>
                              <label className="figures">{item.label}</label>
                              <label className="number"></label>
                            </div>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                ) : null}
                {pieChat.length !== 0 ? (
                  <div className="pieChat crop-showing page-break nobottom">
                    {pieChat.map((data, key) => {
                      var urlImg = baseUrl + data;
                      return (
                        <div className="crop-showing-img">
                          <img src={urlImg} alt="crop Logo" />
                        </div>
                      );
                    })}
                    <label className="figures">
                      Figure 10 : Pie Chart for CHM Points Based on Crop Type
                    </label>
                    <label className="number"></label>
                  </div>
                ) : null}
                <div className="nobottom">
                  {/* bottommore */}
                  <h4 id="normalized" className="common-sub-heading page-break">
                    6.1 Normalized Difference Vegetation Index
                  </h4>
                  {/* <!-- NDVI IMAGE SECTIONS --> */}
                  {ndviMap.length !== 0 ? (
                    <div className="crop-showing">
                      <ul className="ndvi-images">
                        {ndviMap.map((item) => {
                          //{ndviMap.map((item, index) => {
                          var urlImg = baseUrl + item.img;
                          return (
                            // <li className={index !== 0 && index % 11 === 0 ? "page-bottom" : ""}>
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="Satsure Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="crop-showing-img ndi-img">
                        <img src={ndviMapLeg} alt="ndvi leg" />
                      </div>
                      <label className="figures">
                        Figure 11 : Time series variations of NDVI Map <br />
                        Source: (Sentinel 2 Satellite Data)
                      </label>
                    </div>
                  ) : null}
                  <label className="number"></label>
                </div>
                {/* <!-- NDVI CHART SECTIONS --> */}
                {ndviChartImage.length !== 0 ? (
                  <div className="page-break nobottom">
                    <div className="crop-showing">
                      {ndviChartImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img ndvi-chart">
                            <img src={urlImg} alt="ndvi Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Figure 12 : Fortnightly NDVI Trend
                      </label>
                    </div>
                    <div className="text-area">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="300"
                        placeholder="Max 300 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {/* <!-- NDWI IMAGE SECTIONS Normalized Difference Water Index--> */}
                <div className="nobottom">
                  {ndwiMap.length !== 0 ? (
                    <div className="water-index">
                      {/* {ndwiMap.length === 10 ? (
                                        <div> */}
                      <h4
                        id="waterindex"
                        className="common-sub-heading page-break"
                      >
                        6.2 Normalized Difference Water Index
                      </h4>

                      <ul className="ndwi-images">
                        {ndwiMap.map((item, key) => {
                          var urlImg = baseUrl + item.img;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="ndwi Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="crop-showing-img ndi-img">
                        <img src={ndwiMapLeg} alt="Ndvi Map Logo" />
                      </div>
                      <label className="figures">
                        Figure 13 : Time series variations of NDWI Map <br />
                        Source: (Sentinel 2 Satellite Data)
                      </label>
                      {/* </div>
                                    ) : null} */}
                    </div>
                  ) : null}
                  <label className="number"></label>
                </div>

                {/* <!-- NDWI IMAGE SECTIONS --> */}
                {ndwiChartImage.length !== 0 ? (
                  <div className="page-break nobottom">
                    <div className="crop-showing">
                      {ndwiChartImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img ndwi-chart">
                            <img src={urlImg} alt="ndvi chart Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Figure 14 : Fortnightly NDWI Trend
                      </label>
                    </div>
                    <div className="text-area">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="300"
                        placeholder="Max 300 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
              </div>
              {/* <!-- Results and Discussion --> */}
              <div>
                <div className="nobottom">
                  <h3 id="discussion" className="common-heading page-break">
                    7. Results and Discussion
                  </h3>
                  <h4 id="district" className="common-sub-heading">
                    7.1 <span>{district}</span> District
                  </h4>
                  {cropSowingTitle1Image.length !== 0 ? (
                    <div>
                      <h5 className="common-min-heading">7.1.1 Crop Sowing-1</h5>
                      <div className="crop-showing">
                        {cropSowingTitle1Image.map((data, key) => {
                          var urlImg = baseUrl + data;
                          return (
                            <div className="crop-showing-img">
                              <img src={urlImg} alt="crop Logo" />
                            </div>
                          );
                        })}
                        <label className="figures">
                          Figure 15 : Crop sown map 1st update for
                          <span> {district}</span> district
                        </label>
                      </div>
                      <div className="text-area">
                        <TextareaAutosize
                          aria-label="empty textarea"
                          maxLength="500"
                          placeholder="Max 500 characters"
                        />
                      </div>
                    </div>
                  ) : null}
                  <label className="number"></label>
                </div>
                {cropSowing1Image.length !== 0 ? (
                  <div className="page-break nobottom">
                    <div className="crop-showing">
                      {cropSowing1Image.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Figure 16 : Crop sown percentage map for
                        <span> {district}</span> district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {cropSowingTitle2Image.length !== 0 ? (
                  <div className="page-break nobottom">
                    <h5 className="common-min-heading">7.1.2 Crop Sowing-2</h5>
                    <div className="crop-showing">
                      {cropSowingTitle2Image.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Figure 17 : Crop sown Area 2nd update map for
                        <span> {district}</span> district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {cropSowing2Image.length !== 0 ? (
                  <div className="page-break nobottom">
                    <div className="crop-showing">
                      {cropSowing2Image.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                      <label className="figures">
                        Figure 18 : Crop sown percentage map for
                        <span> {district}</span> district
                      </label>
                    </div>
                    <div className="text-area">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}

                {cropAcreageImage.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="common-min-heading page-break">
                      7.1.3 Crop Acreage-1
                    </h5>
                    <div className="crop-showing">
                      {/*  Crop Acreage  */}
                      <ul className="crop-list">
                        {cropAcreageImage.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>

                      <label className="figures">
                        Figure 19 : Crop Acreage map for <span>{district} </span>
                        district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}

                {cropAcreageImage2.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="common-min-heading page-break">
                      7.1.4 Crop Acreage-2
                    </h5>
                    <div className="crop-showing">
                      {/*  Crop Acreage  */}
                      <ul className="crop-list">
                        {cropAcreageImage2.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>

                      <label className="figures">
                        Figure 20 : Crop Acreage map for <span>{district} </span>
                        district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}

                {cropAcreageImage3.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="common-min-heading page-break">
                      7.1.5 Crop Acreage-3
                    </h5>
                    <div className="crop-showing">
                      {/*  Crop Acreage  */}
                      <ul className="crop-list">
                        {cropAcreageImage3.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>

                      <label className="figures">
                        Figure 21 : Crop Acreage map for <span>{district} </span>
                        district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}

                {cropPerformanceImage.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="common-min-heading page-break">
                      7.1.6 Crop Performance
                    </h5>
                    <div className="crop-showing">
                      <ul className="crop-list">
                        {cropPerformanceImage.map((item) => {
                          var urlImg = baseUrl + item;
                          // var urlImg = baseUrl + item.img;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="Crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>

                      <label className="figures">
                        Figure 22 : Crop Performance map for
                        <span> {district} </span> district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {cropHarvest1Image.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="common-min-heading page-break">
                      7.1.7 Crop Harvest-1
                    </h5>
                    <div className="crop-showing">
                      <ul className="crop-list">
                        {cropHarvest1Image.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <label className="figures">
                        Figure 23 : Crop Harvest 1st update for
                        <span> {district} </span>district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {cropHarvest2Image.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="page-break common-min-heading">
                      7.1.8 Crop Harvest-2
                    </h5>
                    <div className="crop-showing">
                      <ul className="crop-list">
                        {cropHarvest2Image.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <label className="figures">
                        Figure 24 : Crop harvest 2nd update for
                        <span> {district}</span> district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
                {cropHarvest3Image.length !== 0 ? (
                  <div className="nobottom">
                    <h5 className="page-break common-min-heading">
                      7.1.9 Crop Harvest-3
                    </h5>
                    <div className="crop-showing">
                      <ul className="crop-list">
                        {cropHarvest3Image.map((item) => {
                          var urlImg = baseUrl + item;
                          return (
                            <li>
                              <div>
                                <div className="crop-showing-img">
                                  <img src={urlImg} alt="crop Logo" />
                                </div>
                                <label className="figures">{item.label}</label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      <label className="figures">
                        Figure 25 : Crop harvest 3nd update for
                        <span>{district}</span> district
                      </label>
                    </div>
                    <div className="text-area">
                      {/* <textarea
                                                placeholder="Max 500 characters"
                                                rows="4"
                                                maxLength="500"
                                            ></textarea> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        maxLength="500"
                        placeholder="Max 500 characters"
                      />
                    </div>
                    <label className="number"></label>
                  </div>
                ) : null}
              </div>
              {/* <!-- Field Photographs --> */}
              {/* {fieldPhotographsImage.length !== 0 ? ( */}
              <div className="field-photographs">
                <div className="nobottom">
                  <h3 id="photographs" className="common-heading page-break">
                    8. Field Photographs
                  </h3>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <div className="upload-remove">
                          <Button
                            variant="contained"
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Upload Image
                          </Button>
                          &nbsp;
                          <Button variant="contained" onClick={onImageRemoveAll}>
                            Remove all images
                          </Button>
                        </div>
                        <div className="field-imgs">
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="img-sections">
                                <div className="upload-img">
                                  <img src={image.data_url} alt="" width="100" />
                                </div>
                                <div className="img-overlay">
                                  <div className="image-item__btn-wrapper">
                                    <div
                                      className="hover-icon"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <img src={upload} alt="upload" />
                                    </div>
                                    <div
                                      className="hover-icon"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <img src={deleteIcon} alt="delete" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-area">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  maxLength="50"
                                  placeholder="Max 50 characters"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>

                  {/* <ul className="photographs-card">
                                    <li className="field-card">
                                        <div className="field-img">
                                            <div class="img-overlay">
                                                <div class="hover-icon">
                                                    <label className="upload-img">
                                                        
                                                        + Add Image
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={fileHandler}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="img-preview">
                                                <img className="preview" src={src} alt={alt} />
                                            </div>
                                            <label className="upload-img">
                                                
                                                + Add Image
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={fileHandler}
                                                />
                                            </label>
                                        </div>
                                        <div className="text-area">
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                maxLength="50"
                                                placeholder="Max 50 characters"
                                            />
                                        </div>
                                    </li>
                                    <li className="field-card">
                                        <div className="field-img">
                                            <div class="img-overlay">
                                                <div class="hover-icon">
                                                    <label className="upload-img">
                                                        
                                                        + Add Image
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={fileHandler}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="img-preview">
                                                <img className="preview" src={src} alt={alt} />
                                            </div>
                                            <label className="upload-img">
                                                
                                                + Add Image
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={fileHandler}
                                                />
                                            </label>
                                        </div>
                                        <div className="text-area">
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                maxLength="50"
                                                placeholder="Max 50 characters"
                                            />
                                        </div>
                                    </li>
                                    <li className="field-card">
                                        <div className="field-img">
                                            <div class="img-overlay">
                                                <div class="hover-icon">
                                                    <label className="upload-img">
                                                        
                                                        + Add Image
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={fileHandler}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="img-preview">
                                                <img className="preview" src={src} alt={alt} />
                                            </div>
                                            <label className="upload-img">
                                                
                                                + Add Image
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={fileHandler}
                                                />
                                            </label>
                                        </div>
                                        <div className="text-area">
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                maxLength="50"
                                                placeholder="Max 50 characters"
                                            />
                                        </div>
                                    </li>
                                    <li className="field-card">
                                        <div className="field-img">
                                            <div class="img-overlay">
                                                <div class="hover-icon">
                                                    <label className="upload-img">
                                                        
                                                        + Add Image
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={fileHandler}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="img-preview">
                                                <img className="preview" src={src} alt={alt} />
                                            </div>
                                            <label className="upload-img">
                                                
                                                + Add Image
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={fileHandler}
                                                />
                                            </label>
                                        </div>
                                        <div className="text-area">
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                maxLength="50"
                                                placeholder="Max 50 characters"
                                            />
                                        </div>
                                    </li> */}

                  {/* <li className="field-card">
                                        <div className="field-img"><label>+ Add Image</label></div>
                                        <div className="text-area">
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                maxLength="50"
                                                placeholder="Max 50 characters"
                                            />
                                        </div>
                                    </li>
                                   */}
                  {/* </ul> */}
                  {/* <ul className="field-photographs-list">
                                    {fieldPhotographsImage.map((data, key) => {
                                        var urlImg = baseUrl + data;
                                        return (
                                            <li>
                                                <div className="crop-showing-img">
                                                    <img src={urlImg} alt="crop Logo" />
                                                </div>
                                                <div className="text-area">
                                                    <TextareaAutosize
                                                        aria-label="empty textarea"
                                                        maxLength="40"
                                                        placeholder="Max 300 characters"
                                                    />
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul> */}
                  <label className="number"></label>
                </div>
                <div className="empty-page nobottom">
                  {/* <ImageUploading
                                    multiple
                                    value={images1}
                                    onChange={onChange1}
                                    maxNumber={maxNumber1}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList1,
                                        onImageUpload1,
                                        onImageRemoveAll1,
                                        onImageUpdate1,
                                        onImageRemove1,
                                        isDragging1,
                                        dragProps1,
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <div className="upload-remove">
                                                <Button variant="contained"
                                                    style={isDragging1 ? { color: "red" } : null}
                                                    onClick={onImageUpload1}
                                                    {...dragProps1}
                                                >
                                                    Upload Image
                                                </Button>
                                                &nbsp;
                                                <Button variant="contained" onClick={onImageRemoveAll1}>Remove all images</Button>
                                            </div>
                                            <div className="field-imgs">
                                                {imageList1.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <div className="img-sections">
                                                            <div className="upload-img">
                                                                <img src={image.data_url} alt="" width="100" />
                                                            </div>
                                                            <div className="img-overlay">
                                                                <div className="image-item__btn-wrapper">
                                                                    <div className="hover-icon" onClick={() => onImageUpdate1(index)}>
                                                                        <img src={upload} alt="upload" />
                                                                    </div>
                                                                    <div className="hover-icon" onClick={() => onImageRemove1(index)}>
                                                                        <img src={deleteIcon} alt="delete" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-area">
                                                            <TextareaAutosize
                                                                aria-label="empty textarea"
                                                                maxLength="50"
                                                                placeholder="Max 50 characters"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading> */}

                  <label className="number"></label>
                </div>
              </div>
              {/* ) : null} */}
              {/* <!-- Conclusion --> */}
              <div className="nobottom">
                <h3 id="conclusion" className="page-break common-heading">
                  9. Conclusion
                </h3>
                <div className="text-area">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    maxLength="1500"
                    placeholder="Max 1500 characters"
                  />
                </div>
                <div className="annexure">
                  <label className="figures">Annexure</label>
                  <div className="annexure-table">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="5">List of Abbreviations</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>GIS</td>
                          <td>Geographic Information System</td>
                          <td rowSpan="6"></td>
                          <td>NDWI</td>
                          <td>Normalized Difference Water Index</td>
                        </tr>
                        <tr>
                          <td>CHM</td>
                          <td>Crop Health Monitoring</td>
                          <td>NIR</td>
                          <td>Near Infrared Radiation</td>
                        </tr>
                        <tr>
                          <td>IMD</td>
                          <td>India Meteorological Department</td>
                          <td>NRSC</td>
                          <td>National Remote Sensing Centre</td>
                        </tr>
                        <tr>
                          <td>LPA</td>
                          <td>Long Period Average</td>
                          <td>GPM</td>
                          <td>Global Precipitation Monitoring</td>
                        </tr>
                        <tr>
                          <td>MNCFC</td>
                          <td>Mahalanobis National Crop Forecast Centre</td>
                          <td>RGICL</td>
                          <td>Reliance General Insurance Company Limited</td>
                        </tr>
                        <tr>
                          <td>NDVI</td>
                          <td>Normalized Difference Vegetation Index</td>
                          <td>SWIR</td>
                          <td>Short-wave Infrared Radiation</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {cropCalendarImage.length !== 0 ? (
                  <div>
                    <label className="figures">Crop Calendar</label>
                    <div className="crop-showing crop-cal">
                      {cropCalendarImage.map((data, key) => {
                        var urlImg = baseUrl + data;
                        return (
                          <div className="crop-showing-img">
                            <img src={urlImg} alt="crop Logo" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                <label className="number"></label>
              </div>
              {/* <!-- Footer Logo --> */}
              <div className="footer-logo">
                <div className="logo-icon">
                  {/* <img src={relianceLogo} alt="Reliance Logo" /> */}
                  <img src={satsureLogo} alt="Satsure Logo" />
                </div>
                {/* <div id="content">
                                <div id="pageFooter">Page </div>
                                multi-page content here...
                            </div> */}
                {/* <div id="page-number"></div> */}
                {/* <div class="page-number"></div> */}
              </div>
            </div>
          </div>
          {/* </PDFExport> */}

          {(() => {
            if (pageLoader) {
              return (
                <div className="page-loader-sections">
                  <PageLoader />
                </div>
              );
            }
          })()}
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader-container">
        <div className="loader">
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
};
export default PdfComponent;

import React, { Fragment } from 'react';
import './App.css';
// Router
import Routes from './router/Routes';
function App() {
  return (
    <Fragment>
      <Routes />
    </Fragment>
  );
}

export default App;
